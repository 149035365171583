import React from "react"
import GardenPage from "../components/Garden/GardenPage"
import Seo from "../components/Seo/Seo"

export default function GardenBlog({ location }) {
  return (
    <>
      <Seo title="ChemToCode | Garden" pathname={location.pathname} />
      <GardenPage />
    </>
  )
}
