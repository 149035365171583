import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Link } from "gatsby"
import Img from "gatsby-image"

//Styles
import GardenPageStyles from "./GardenPage.module.scss"

export default function Gardencard({ allPosts }) {
  const { node: posts } = allPosts

  return (
    <div className={GardenPageStyles.border}>
      <Link to={`/garden${posts.fields.slug}`}>
        <div className={GardenPageStyles.blogPosts}>
          <li key={uuidv4()}>
            <p className={GardenPageStyles.tag}>
              {posts.frontmatter.categories}
            </p>
            <p className={GardenPageStyles.title}>{posts.frontmatter.title}</p>
            <p className={GardenPageStyles.date}>{posts.frontmatter.date}</p>
            <Img
              fluid={posts.frontmatter.images.childImageSharp.fluid}
              alt={`Image of ${posts.frontmatter.title}`}
              className={GardenPageStyles.gardenImg}
            />

            {/* <p className={GardenPageStyles.excerpt}>{posts.excerpt}</p> */}
            {/* <p className={GardenPageStyles.readingTime}>
              {posts.fields.readingTime.text}
            </p> */}
            {/* <p className={GardenPageStyles.date}>{posts.frontmatter.date}</p> */}
          </li>
        </div>
      </Link>
    </div>
  )
}
