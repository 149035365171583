import { useStaticQuery, graphql } from "gatsby"

export const BlogPosts = () => {
  // graphQL query for all blog posts ordered with the latest first. Query is filtered on type === 'blog' to only collate blog posts
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { type: { eq: "blog" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "MMMM Do, YYYY")
              categories
              images {
                childImageSharp {
                  fluid(sizes: "(max-width: 500px), 500", quality: 100) {
                    aspectRatio
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            fields {
              slug
              readingTime {
                text
              }
            }
          }
        }
      }
    }
  `)
  return { allMdx }
}
