import React from "react"
// import { useState } from "react"

//styles
import GardenPageStyles from "./GardenPage.module.scss"

export default function CategoryNav({ blogCategories, setCategory, category }) {
  //Create an Array of categories from graphql query
  const categories = blogCategories.map(
    cats => cats.node.frontmatter.categories
  )
  // filter array with only unique categories to create dynamic buttons
  const uniqueCategories = ["All", ...new Set(categories)]

  function handleCategory(event) {
    const category = event.target.value
    setCategory(category)
  }

  return (
    <div>
      {uniqueCategories.map(cat => {
        return (
          <button
            className={
              category === cat
                ? GardenPageStyles.active
                : GardenPageStyles.default
            }
            key={cat}
            value={cat}
            onClick={handleCategory}
          >
            {cat}
          </button>
        )
      })}
    </div>
  )
}
