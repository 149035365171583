import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

//Styles
import GardenPageStyles from "./GardenPage.module.scss"

//graphql
import { BlogPosts } from "../graphqlQueries/BlogPosts"

//components
import GardenCard from "./GardenCard"
import GardenPageDesktop from "./GardenPageDesktop"
import CategoryNav from "./CategoryNav"
import MobileSvg from "../MobileSVG/MobileSvg"

export default function GardenPage() {
  const {
    allMdx: { edges },
  } = BlogPosts()
  const [blogs, setBlogs] = useState(edges)
  const [category, setCategory] = useState("All")

  // filter blogs based on category chosen
  useEffect(() => {
    let filteredBlogs = edges
    if (category !== "All") {
      filteredBlogs = filteredBlogs.filter(cat => {
        return cat.node.frontmatter.categories === `${category}`
      })
    }
    setBlogs(filteredBlogs)
  }, [category, edges])

  // filter blogs for only top 3 to display as posters

  const breakpoints = useBreakpoint()
  return (
    <>
      <div className={GardenPageStyles.container}>
        <div className={GardenPageStyles.wrapper}>
          <div className={GardenPageStyles.categoryNav}>
            <CategoryNav
              setCategory={setCategory}
              category={category}
              blogCategories={edges}
            />
          </div>
          <ul>
            {blogs.map(posts => {
              return <GardenCard key={uuidv4()} allPosts={posts} />
            })}
          </ul>
        </div>
        {breakpoints.md ? <MobileSvg /> : <GardenPageDesktop />}
      </div>
    </>
  )
}
