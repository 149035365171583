import React from "react"

//styles
import GardenPageStyles from "./GardenPage.module.scss"

export default function Gardenpagedesktop() {
  return (
    <div className={GardenPageStyles.gardenSvg}>
      <svg
        aria-labelledby="gardenPageSvg"
        role="img"
        preserveAspectRatio="xMinYMin meet"
        width="90%"
        height="100%"
        fill="none"
        viewBox="0 0 1173 555"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title id="gardenPageSvg">
          Garden page svg with a beaker plant and sparkles animations
        </title>
        <path
          d="M1057.86 242.449l-9.46-90.422 15.82 89.512-6.36.91z"
          fill="#056734"
        />
        <path
          d="M1024.74 189.324c8.56-3.093 17.47-.546 19.84 5.822 2.36 6.367-2.73 14.191-11.28 17.284-8.56 3.092-23.29-4.549-23.29-4.549s6.18-15.282 14.73-18.557z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M1053.85 193.144l-41.3 13.646 40.58-15.647.72 2.001z"
          fill="#056734"
        />
        <path
          d="M1040.75 196.783l-8.91-5.276 9.1 5.094-.19.182zM1021.83 203.515l-3.82-2.183 4.01 2.001-.19.182zM1027.66 201.513l-6.19-3.638 6.37 3.457-.18.181zM1033.48 199.33l-7.46-4.366 7.64 4.184-.18.182zM1040.76 196.965l-3.83 9.643 3.64-9.643h.19zM1021.83 203.879l-1.63 4.002 1.45-4.184.18.182zM1027.66 201.696l-2.73 6.731 2.54-6.731h.19zM1033.48 199.512l-3.28 8.187 3.1-8.187h.18z"
          fill="#056734"
        />
        <path
          d="M1045.3 212.066c4.73-4.185 6.19-10.371 3.1-13.827-3.1-3.457-9.46-3.093-14.19 1.091-4.73 4.185-5.1 15.647-5.1 15.647s11.46 1.273 16.19-2.911z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M1053.85 194.418l-23.1 19.103 22.19-20.195.91 1.092z"
          fill="#056734"
        />
        <path
          d="M1046.39 199.876v7.277l-.18-7.277h.18zM1036.02 208.973l-.18 3.093v-3.093h.18zM1039.3 206.244l-.18 5.094v-5.094h.18zM1046.21 200.058l-7.09-1.092 7.09.91v.182zM1035.66 209.155l-3.09-.546 3.09.364v.182zM1038.94 206.244l-5.1-.728 5.1.546v.182zM1042.21 203.515l-6-.91 6 .728v.182zM1058.04 219.525c-1.27-.182-2.55-.728-3.64-1.273-1.09-.546-2.18-1.456-3.09-2.366-.91-.909-1.64-2.001-2-3.093-.55-1.091-.73-2.365-.91-3.638.18 1.273.54 2.365 1.09 3.457.54 1.091 1.27 2.183 2.18 2.911.91.909 1.82 1.455 2.91 2.001 1.09.546 2.37.909 3.46.909v1.092z"
          fill="#056734"
        />
        <path
          d="M1052.22 212.793c-1.82.546-6.19.364-6.92-1.455-.54-1.819 2.91-4.548 4.73-5.094 1.82-.546 3.82.364 4.37 2.183.55 1.819-.36 3.821-2.18 4.366z"
          fill="#DF6171"
        />
        <path
          d="M1046.39 218.615c-1.09-1.637-2.36-5.821-.72-6.913 1.63-1.092 5.27 1.273 6.36 2.911 1.1 1.637.73 3.821-.72 4.912-1.64 1.092-3.82.728-4.92-.91z"
          fill="#DF6171"
        />
        <path
          d="M1038.94 214.977c1.09-1.638 4.73-4.003 6.36-2.911 1.64 1.092.37 5.458-.72 6.913-1.1 1.638-3.28 1.82-4.92.728-1.63-1.091-1.82-3.275-.72-4.73z"
          fill="#DF6171"
        />
        <path
          d="M1040.39 206.608c1.82.546 5.28 3.275 4.73 5.094-.54 1.819-4.91 2.001-6.91 1.455-2-.545-2.91-2.547-2.19-4.366.73-1.819 2.37-2.729 4.37-2.183z"
          fill="#DF6171"
        />
        <path
          d="M1048.58 205.334c0 2.002-1.64 6.004-3.46 6.004-2 0-3.46-4.184-3.46-6.004 0-2.001 1.64-3.457 3.46-3.457s3.46 1.638 3.46 3.457z"
          fill="#DF6171"
        />
        <path
          d="M1045.12 212.612c.5 0 .91-.408.91-.91s-.41-.91-.91-.91-.91.408-.91.91.41.91.91.91zM1045.12 209.519c.3 0 .55-.245.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.244-.54.546 0 .301.24.546.54.546zM1046.94 210.246c.3 0 .55-.244.55-.545a.55.55 0 00-.55-.546c-.3 0-.54.244-.54.546 0 .301.24.545.54.545z"
          fill="#EFEBE4"
        />
        <path
          d="M1045.85 209.883c.2 0 .36-.163.36-.364a.361.361 0 00-.36-.364c-.2 0-.36.163-.36.364 0 .201.16.364.36.364zM1047.12 211.156c.2 0 .37-.163.37-.364a.37.37 0 00-.37-.364c-.2 0-.36.163-.36.364 0 .201.16.364.36.364zM1045.12 214.795c.3 0 .55-.245.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.245-.54.546 0 .301.24.546.54.546zM1047.85 212.248c.3 0 .55-.245.55-.546a.55.55 0 00-1.1 0c0 .301.25.546.55.546zM1046.94 214.067c.3 0 .55-.244.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.245-.54.546 0 .302.24.546.54.546zM1043.3 210.246c.3 0 .55-.244.55-.545a.55.55 0 00-.55-.546c-.3 0-.54.244-.54.546 0 .301.24.545.54.545zM1042.39 212.248c.3 0 .55-.245.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.244-.54.546 0 .301.24.546.54.546zM1043.3 214.067c.3 0 .55-.244.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.245-.54.546 0 .302.24.546.54.546zM1047.12 212.975c.2 0 .37-.162.37-.363a.37.37 0 00-.37-.364c-.2 0-.36.163-.36.364 0 .201.16.363.36.363z"
          fill="#EFEBE4"
        />
        <path
          d="M1045.85 214.067c.2 0 .36-.163.36-.364a.361.361 0 00-.36-.364c-.2 0-.36.163-.36.364 0 .201.16.364.36.364zM1044.39 209.883c.2 0 .37-.163.37-.364a.37.37 0 00-.37-.364c-.2 0-.36.163-.36.364 0 .201.16.364.36.364zM1043.12 211.156c.2 0 .36-.163.36-.364a.361.361 0 00-.36-.364c-.2 0-.36.163-.36.364 0 .201.16.364.36.364zM1043.12 212.975c.2 0 .36-.162.36-.363a.361.361 0 00-.36-.364c-.2 0-.36.163-.36.364 0 .201.16.363.36.363zM1044.39 214.067c.2 0 .37-.163.37-.364a.37.37 0 00-.37-.364c-.2 0-.36.163-.36.364 0 .201.16.364.36.364z"
          fill="#EFEBE4"
        />
        <path
          d="M1058.04 261.188l47.67-144.82-38.76 147.549-8.91-2.729z"
          fill="#056734"
        />
        <path
          d="M1090.97 165.672c3.64-.182 7.46-.546 11.1-1.637 1.82-.546 3.45-1.274 5.09-2.183 1.64-.91 2.91-2.366 3.64-4.185-.55 1.819-2 3.275-3.46 4.366-1.63 1.092-3.27 2.002-5.09 2.548-3.64 1.273-7.28 2.001-11.1 2.365l-.18-1.274z"
          fill="#056734"
        />
        <path
          d="M1122.08 152.209c-7.1 5.64-16.37 5.822-20.56.546-4.18-5.276-1.82-14.191 5.46-19.831 7.28-5.64 23.47-2.911 23.47-2.911s-1.27 16.556-8.37 22.196z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M1093.15 157.667l34.93-25.835-33.65 27.654-1.28-1.819z"
          fill="#056734"
        />
        <path
          d="M1104.43 150.208l10.19 2.183-10.19-2.001v-.182zM1120.26 137.836l4.37.91-4.37-.728v-.182zM1115.35 141.657l7.09 1.455-7.09-1.273v-.182zM1110.44 145.477l8.73 1.82-8.73-1.638v-.182zM1104.43 150.026l.55-10.37-.37 10.37h-.18zM1120.26 137.654l.36-4.366-.18 4.366h-.18zM1115.35 141.475l.54-7.277-.36 7.277h-.18zM1110.44 145.295l.54-8.914-.36 8.914h-.18z"
          fill="#056734"
        />
        <path
          d="M1114.62 125.283c-2.55.909-8.73.545-9.64-2.002-.91-2.547 4-6.367 6.55-7.277 2.54-.91 5.27.546 6.18 3.093.91 2.729-.54 5.458-3.09 6.186z"
          fill="#DF6171"
        />
        <path
          d="M1106.43 133.652c-1.63-2.184-3.27-8.187-1.09-9.825 2.19-1.637 7.46 1.819 8.92 4.003 1.63 2.183 1.09 5.276-1.09 6.913-2.19 1.638-5.1 1.092-6.74-1.091z"
          fill="#DF6171"
        />
        <path
          d="M1096.06 128.376c1.64-2.184 6.73-5.64 8.92-4.003 2.18 1.637.54 7.641-1.09 9.825-1.64 2.183-4.73 2.729-6.92 1.091-2.18-1.637-2.54-4.73-.91-6.913z"
          fill="#DF6171"
        />
        <path
          d="M1097.88 116.732c2.55.909 7.46 4.73 6.55 7.277-.91 2.547-7.09 2.911-9.64 2.001-2.55-.909-4-3.638-3.09-6.185.72-2.548 3.45-3.821 6.18-3.093z"
          fill="#DF6171"
        />
        <path
          d="M1109.34 114.912c0 2.729-2.18 8.551-4.91 8.551s-4.91-5.822-4.91-8.551a4.89 4.89 0 014.91-4.912 4.89 4.89 0 014.91 4.912z"
          fill="#DF6171"
        />
        <path
          d="M1104.61 125.101a1.456 1.456 0 10.009-2.911 1.456 1.456 0 00-.009 2.911zM1104.61 120.734c.41 0 .73-.326.73-.728a.724.724 0 10-1.45 0c0 .402.32.728.72.728zM1106.98 121.644c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728z"
          fill="#EFEBE4"
        />
        <path
          d="M1105.71 121.462c.3 0 .54-.245.54-.546a.544.544 0 10-1.09 0c0 .301.24.546.55.546zM1107.53 123.099c.3 0 .54-.244.54-.546a.541.541 0 00-.54-.545c-.31 0-.55.244-.55.545 0 .302.24.546.55.546zM1104.61 128.193c.41 0 .73-.325.73-.727a.725.725 0 10-1.45 0c0 .402.32.727.72.727zM1108.43 124.373c.41 0 .73-.326.73-.728a.725.725 0 10-1.45 0c0 .402.32.728.72.728zM1107.16 127.284c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1102.07 121.826c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1100.79 124.373c.41 0 .73-.326.73-.728a.725.725 0 10-1.45 0c0 .402.32.728.72.728zM1102.07 127.102c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1107.34 125.646c.3 0 .55-.244.55-.546a.55.55 0 00-.55-.545c-.3 0-.54.244-.54.545 0 .302.24.546.54.546zM1105.71 127.102c.3 0 .54-.245.54-.546a.542.542 0 00-.54-.546c-.31 0-.55.244-.55.546 0 .301.24.546.55.546zM1103.52 121.462c.3 0 .55-.245.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.245-.54.546 0 .301.24.546.54.546zM1101.7 123.099c.3 0 .55-.244.55-.546a.55.55 0 00-.55-.545c-.3 0-.54.244-.54.545 0 .302.24.546.54.546z"
          fill="#EFEBE4"
        />
        <path
          d="M1101.89 125.646c.3 0 .54-.244.54-.546a.541.541 0 00-.54-.545c-.31 0-.55.244-.55.545 0 .302.24.546.55.546zM1103.52 127.102c.3 0 .55-.245.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.244-.54.546 0 .301.24.546.54.546z"
          fill="#EFEBE4"
        />
        <path
          d="M1104.43 201.514c-6.91 4.002-14.92 3.092-17.83-2.002-2.91-5.094.18-12.553 7.1-16.556 6.91-4.002 20.74.364 20.74.364s-3.09 14.191-10.01 18.194z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M1078.96 202.787l33.3-18.012-32.39 19.649-.91-1.637z"
          fill="#056734"
        />
        <path
          d="M1089.51 197.693l8.55 3.093-8.55-2.911v-.182zM1104.8 188.96l3.45 1.274-3.64-1.092.19-.182zM1100.07 191.689l6 2.183-6.19-2.001.19-.182zM1095.34 194.418l7.27 2.547-7.27-2.365v-.182zM1089.51 197.511l1.82-8.915-1.63 8.915h-.19zM1104.8 188.778l.72-3.821-.54 3.821h-.18zM1100.07 191.507l1.27-6.368-1.09 6.368h-.18zM1095.34 194.236l1.45-7.641-1.27 7.641h-.18z"
          fill="#056734"
        />
        <path
          d="M1121.72 161.852c-2.55.909-8.74.545-9.65-2.002-.91-2.547 4.01-6.367 6.55-7.277 2.55-.91 5.28.546 6.19 3.093.91 2.729-.55 5.458-3.09 6.186z"
          fill="#F39FA6"
        />
        <path
          d="M1113.35 170.221c-1.64-2.184-3.28-8.188-1.09-9.825 2.18-1.637 7.46 1.819 8.91 4.003 1.64 2.183 1.09 5.276-1.09 6.913-2 1.638-5.1 1.092-6.73-1.091z"
          fill="#F39FA6"
        />
        <path
          d="M1102.98 164.945c1.63-2.184 6.73-5.64 8.91-4.003 2.18 1.637.55 7.641-1.09 9.824-1.64 2.184-4.73 2.73-6.91 1.092-2-1.637-2.55-4.73-.91-6.913z"
          fill="#F39FA6"
        />
        <path
          d="M1104.8 153.301c2.54.909 7.46 4.73 6.55 7.277-.91 2.547-7.1 2.911-9.65 2.001-2.54-.909-4-3.638-3.09-6.185.91-2.548 3.64-3.821 6.19-3.093z"
          fill="#F39FA6"
        />
        <path
          d="M1116.44 151.481c0 2.729-2.18 8.551-4.91 8.551s-4.91-5.822-4.91-8.551a4.89 4.89 0 014.91-4.912 4.89 4.89 0 014.91 4.912z"
          fill="#F39FA6"
        />
        <path
          d="M1111.53 161.67c.8 0 1.45-.652 1.45-1.456a1.455 1.455 0 10-1.45 1.456zM1111.53 157.303c.4 0 .73-.326.73-.728a.73.73 0 00-1.46 0c0 .402.33.728.73.728zM1114.07 158.213c.41 0 .73-.326.73-.728a.725.725 0 10-1.45 0c0 .402.32.728.72.728zM1112.62 158.031c.3 0 .55-.245.55-.546a.55.55 0 00-1.1 0c0 .301.25.546.55.546zM1114.44 159.668c.3 0 .54-.244.54-.546a.541.541 0 00-.54-.545.55.55 0 00-.55.545c0 .302.25.546.55.546zM1111.53 164.762c.4 0 .73-.325.73-.727a.731.731 0 00-1.46 0c0 .402.33.727.73.727zM1115.35 160.942c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1114.07 163.853c.41 0 .73-.326.73-.728a.725.725 0 10-1.45 0c0 .402.32.728.72.728zM1108.98 158.395c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1107.89 160.942c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1108.98 163.671c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1114.44 162.215c.3 0 .54-.244.54-.546a.541.541 0 00-.54-.545.55.55 0 00-.55.545c0 .302.25.546.55.546z"
          fill="#EFEBE4"
        />
        <path
          d="M1112.8 163.671c.3 0 .55-.245.55-.546a.55.55 0 00-.55-.546c-.3 0-.54.245-.54.546 0 .301.24.546.54.546zM1110.62 158.031c.3 0 .54-.245.54-.546a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.546.55.546zM1108.8 159.668c.3 0 .54-.244.54-.546a.541.541 0 00-.54-.545.55.55 0 00-.55.545c0 .302.25.546.55.546zM1108.8 162.215c.3 0 .54-.244.54-.546a.541.541 0 00-.54-.545.55.55 0 00-.55.545c0 .302.25.546.55.546zM1110.44 163.671c.3 0 .54-.245.54-.546a.544.544 0 10-1.09 0c0 .301.24.546.55.546z"
          fill="#EFEBE4"
        />
        <path
          d="M1062.59 248.453l4.18-136.997 4.37 136.997h-8.55z"
          fill="#056734"
        />
        <path
          d="M1084.06 133.288c-2.92 6.367-9.28 9.824-14.01 7.641s-6.37-9.097-3.46-15.647c2.91-6.367 15.1-10.37 15.1-10.37s5.28 11.826 2.37 18.376z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M1066.23 147.297l14.91-30.202-13.46 30.929-1.45-.727z"
          fill="#056734"
        />
        <path
          d="M1071.32 138.018l7.82-2.001-7.82 2.183v-.182zM1077.87 124.009l3.27-.91-3.27 1.092v-.182zM1075.87 128.375l5.46-1.455-5.46 1.637v-.182zM1073.87 132.742l6.55-1.819-6.55 2.001v-.182zM1071.14 138.018l-3.28-7.459 3.46 7.277-.18.182zM1077.69 123.827l-1.28-3.093 1.46 2.911-.18.182zM1075.69 128.193l-2.19-5.276 2.37 5.095-.18.181zM1073.69 132.56l-2.73-6.186 2.91 6.186h-.18zM1066.77 157.303c-2-.364-3.82-1.273-5.46-2.547-1.63-1.274-2.91-2.729-4-4.366-1.09-1.638-1.82-3.457-2.36-5.277-.19-.909-.37-1.819-.73-2.729l-.37-2.911.55 2.729c.18.91.55 1.82.73 2.729.73 1.82 1.45 3.457 2.54 5.095 1.1 1.455 2.37 2.911 4.01 3.82 1.45 1.092 3.27 1.82 4.91 2.002l.18 1.455z"
          fill="#056734"
        />
        <path
          d="M1064.41 140.747c-2.55.91-8.74.546-9.65-2.001-.91-2.547 4.01-6.368 6.55-7.278 2.55-.909 5.28.546 6.19 3.093.91 2.547-.55 5.458-3.09 6.186z"
          fill="#F39FA6"
        />
        <path
          d="M1056.04 149.116c-1.64-2.183-3.28-8.187-1.09-9.824 2.18-1.638 7.46 1.819 8.91 4.002 1.64 2.183 1.09 5.276-1.09 6.914-2.18 1.637-5.09 1.091-6.73-1.092z"
          fill="#F39FA6"
        />
        <path
          d="M1045.67 143.658c1.63-2.183 6.73-5.64 8.91-4.002 2.19 1.637.55 7.641-1.09 9.824-1.64 2.183-4.73 2.729-6.91 1.092-2-1.638-2.55-4.549-.91-6.914z"
          fill="#F39FA6"
        />
        <path
          d="M1047.49 132.196c2.54.91 7.46 4.731 6.55 7.278-.91 2.547-7.1 2.911-9.65 2.001-2.54-.91-4-3.639-3.09-6.186.91-2.547 3.64-4.002 6.19-3.093z"
          fill="#F39FA6"
        />
        <path
          d="M1059.13 130.377c0 2.729-2.18 8.551-4.91 8.551s-4.91-5.822-4.91-8.551a4.89 4.89 0 014.91-4.913 4.89 4.89 0 014.91 4.913z"
          fill="#F39FA6"
        />
        <path
          d="M1054.4 140.565c.8 0 1.46-.652 1.46-1.455 0-.804-.66-1.456-1.46-1.456-.8 0-1.46.652-1.46 1.456 0 .803.66 1.455 1.46 1.455zM1054.4 136.199c.4 0 .73-.326.73-.728a.731.731 0 00-1.46 0c0 .402.33.728.73.728zM1056.77 137.108c.4 0 .72-.326.72-.727a.725.725 0 10-1.45 0c0 .401.32.727.73.727z"
          fill="#EFEBE4"
        />
        <path
          d="M1055.31 136.744c.3 0 .55-.244.55-.545a.55.55 0 00-1.1 0c0 .301.25.545.55.545zM1057.13 138.564c.3 0 .54-.245.54-.546a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.546.55.546zM1054.4 143.658c.4 0 .73-.326.73-.728a.73.73 0 00-1.46 0c0 .402.33.728.73.728zM1058.04 139.837c.4 0 .73-.326.73-.727a.731.731 0 00-1.46 0c0 .401.33.727.73.727zM1056.77 142.566c.4 0 .72-.325.72-.727a.725.725 0 10-1.45 0c0 .402.32.727.73.727zM1051.67 137.108c.4 0 .73-.326.73-.727a.731.731 0 00-1.46 0c0 .401.33.727.73.727zM1050.58 139.837c.4 0 .73-.326.73-.727a.731.731 0 00-1.46 0c0 .401.33.727.73.727zM1051.67 142.566c.4 0 .73-.325.73-.727a.731.731 0 00-1.46 0c0 .402.33.727.73.727z"
          fill="#EFEBE4"
        />
        <path
          d="M1057.13 141.111c.3 0 .54-.245.54-.546a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.546.55.546zM1055.49 142.566c.3 0 .55-.244.55-.545a.55.55 0 00-.55-.546c-.3 0-.54.244-.54.546 0 .301.24.545.54.545zM1053.31 136.744c.3 0 .54-.244.54-.545a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.545.55.545zM1051.49 138.564c.3 0 .54-.245.54-.546a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.546.55.546zM1051.49 141.111c.3 0 .54-.245.54-.546a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.546.55.546zM1053.13 142.566c.3 0 .54-.244.54-.545a.542.542 0 00-.54-.546.55.55 0 00-.55.546c0 .301.25.545.55.545z"
          fill="#EFEBE4"
        />
        <path
          d="M1094.97 192.053c-6 6.913-15.1 8.733-20.19 4.184-5.1-4.548-4.37-13.645 1.63-20.558 6.01-6.914 22.56-7.096 22.56-7.096s2.19 16.738-4 23.47z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M1067.68 202.787l29.66-31.839-28.02 33.295-1.64-1.456z"
          fill="#056734"
        />
        <path
          d="M1077.32 193.326l10.37.182h-10.37v-.182zM1090.79 178.226l4.36.182-4.36.182v-.364zM1086.6 182.956l7.28.182h-7.28v-.182zM1082.42 187.505l8.91.363h-8.91v-.363zM1077.32 193.144l-1.27-10.37 1.46 10.189-.19.181zM1090.61 178.044l-.37-4.367.73 4.367h-.36zM1086.42 182.774l-.73-7.277 1.09 7.095-.36.182zM1082.42 187.323l-1.09-8.733 1.27 8.733h-.18z"
          fill="#056734"
        />
        <path
          d="M1050.76 165.49c7.64 5.094 10.74 13.645 6.91 19.467-3.82 5.64-12.91 6.186-20.55 1.092-7.65-5.094-10.01-21.468-10.01-21.468s16.01-4.185 23.65.909z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M1064.95 191.143l-35.48-25.107 36.76 23.288-1.28 1.819z"
          fill="#056734"
        />
        <path
          d="M1054.4 182.774l-1.09-10.37 1.27 10.37h-.18zM1037.66 171.676l-.36-4.366.54 4.366h-.18zM1042.76 175.133l-.73-7.278 1.09 7.278h-.36zM1048.03 178.589l-1.09-8.732 1.27 8.732h-.18zM1054.22 182.956l-10.19 2.729 10.01-3.093.18.364zM1037.48 171.676l-4.37 1.092 4.19-1.274.18.182zM1042.57 175.133l-7.09 1.819 7.09-2.001v.182zM1047.85 178.59l-8.55 2.365 8.37-2.547.18.182z"
          fill="#056734"
        />
        <path
          d="M1146.5 491H47.5c-6.6 0-12-5.4-12-12v-35h1123v35c0 6.6-5.4 12-12 12z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M1163 444H31c-5.5 0-10-4.5-10-10s4.5-10 10-10h1132c5.5 0 10 4.5 10 10s-4.5 10-10 10z"
          fill="#EACDA3"
        />
        <g opacity=".3">
          <path
            d="M839.3 423.5h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M846.3 423.5h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9v84.3c0 2.8-2.3 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M842.7 419.9h-38.6c-2.8 0-5-2.3-5-5v-49.8h48.7v49.8c-.1 2.7-2.3 5-5.1 5z"
            fill="url(#paint8_linear)"
          />
          <path
            d="M852.6 334.2h-58.5c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8h58.5c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M823.4 367.8c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
            fill="#F5AFAE"
          />
          <path
            opacity=".27"
            d="M842 334.2v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5H842zM806.1 399.8c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.1-1.7 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <path
            d="M818.1 347.6h-19.8v.4h19.8v-.4zM818.1 362.7h-19.8v.4h19.8v-.4zM818.1 377.8h-19.8v.4h19.8v-.4zM818.1 392.9h-19.8v.4h19.8v-.4zM818.1 408.1h-19.8v.4h19.8v-.4zM805.7 353h-7.1v.4h7.1v-.4zM805.7 349.7h-7.1v.4h7.1v-.4zM805.7 356.2h-7.1v.4h7.1v-.4zM805.7 359.4h-7.1v.4h7.1v-.4zM805.7 368.1h-7.1v.4h7.1v-.4zM805.7 364.8h-7.1v.4h7.1v-.4zM805.7 371.3h-7.1v.4h7.1v-.4zM805.7 374.6h-7.1v.4h7.1v-.4zM805.7 383.8h-7.1v.4h7.1v-.4zM805.7 380.5h-7.1v.4h7.1v-.4zM805.7 387h-7.1v.4h7.1v-.4zM805.7 390.2h-7.1v.4h7.1v-.4zM805.7 398.9h-7.1v.4h7.1v-.4zM805.7 395.6h-7.1v.4h7.1v-.4zM805.7 402.1h-7.1v.4h7.1v-.4zM805.7 405.4h-7.1v.4h7.1v-.4z"
            fill="#1E1E1C"
          />
          <path
            d="M909.2 423.5h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M916.1 423.5h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9v84.3c.1 2.8-2.2 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M912.5 419.9h-38.6c-2.8 0-5-2.3-5-5v-49.8h48.7v49.8c0 2.7-2.3 5-5.1 5z"
            fill="url(#paint9_linear)"
          />
          <path
            d="M922.5 334.2H864c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8h58.5c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M893.2 367.8c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
            fill="#B3DAD0"
          />
          <path
            opacity=".27"
            d="M911.8 334.2v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5h-9.8zM876 399.8c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.1-1.8 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <path
            d="M887.9 347.6h-19.8v.4h19.8v-.4zM887.9 362.7h-19.8v.4h19.8v-.4zM887.9 377.8h-19.8v.4h19.8v-.4zM887.9 392.9h-19.8v.4h19.8v-.4zM887.9 408.1h-19.8v.4h19.8v-.4zM875.5 353h-7.1v.4h7.1v-.4zM875.5 349.7h-7.1v.4h7.1v-.4zM875.5 356.2h-7.1v.4h7.1v-.4zM875.5 359.4h-7.1v.4h7.1v-.4zM875.5 368.1h-7.1v.4h7.1v-.4zM875.5 364.8h-7.1v.4h7.1v-.4zM875.5 371.3h-7.1v.4h7.1v-.4zM875.5 374.6h-7.1v.4h7.1v-.4zM875.5 383.8h-7.1v.4h7.1v-.4zM875.5 380.5h-7.1v.4h7.1v-.4zM875.5 387h-7.1v.4h7.1v-.4zM875.5 390.2h-7.1v.4h7.1v-.4zM875.5 398.9h-7.1v.4h7.1v-.4zM875.5 395.6h-7.1v.4h7.1v-.4zM875.5 402.1h-7.1v.4h7.1v-.4zM875.5 405.4h-7.1v.4h7.1v-.4z"
            fill="#1E1E1C"
          />
          <path
            d="M980.7 424.1h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M987.6 424.1h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9V419c.1 2.8-2.2 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M984 420.4h-38.6c-2.8 0-5-2.3-5-5v-49.8H989v49.8c0 2.7-2.2 5-5 5z"
            fill="url(#paint10_linear)"
          />
          <path
            d="M994 334.7h-58.5c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8H994c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M964.7 368.3c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
            fill="#F5AFAE"
          />
          <path
            opacity=".27"
            d="M983.3 334.7v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5h-9.8zM947.4 400.3c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.2-1.7 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <path
            d="M959.4 348.1h-19.8v.4h19.8v-.4zM959.4 363.2h-19.8v.4h19.8v-.4zM959.4 378.3h-19.8v.4h19.8v-.4zM959.4 393.5h-19.8v.4h19.8v-.4zM959.4 408.6h-19.8v.4h19.8v-.4zM947 353.5h-7.1v.4h7.1v-.4zM947 350.3h-7.1v.4h7.1v-.4zM947 356.7h-7.1v.4h7.1v-.4zM947 360h-7.1v.4h7.1v-.4zM947 368.6h-7.1v.4h7.1v-.4zM947 365.4h-7.1v.4h7.1v-.4zM947 371.9h-7.1v.4h7.1v-.4zM947 375.1h-7.1v.4h7.1v-.4zM947 384.3h-7.1v.4h7.1v-.4zM947 381h-7.1v.4h7.1v-.4zM947 387.5h-7.1v.4h7.1v-.4zM947 390.8h-7.1v.4h7.1v-.4zM947 399.4h-7.1v.4h7.1v-.4zM947 396.2h-7.1v.4h7.1v-.4zM947 402.6h-7.1v.4h7.1v-.4zM947 405.9h-7.1v.4h7.1v-.4z"
            fill="#1E1E1C"
          />
          <path
            d="M802.1 414.8c-1.5-.5-2.3-2.2-1.7-3.7l53.8-150.2c.5-1.5 2.2-2.3 3.7-1.7 1.5.5 2.3 2.2 1.7 3.7l-53.8 150.2c-.6 1.4-2.3 2.2-3.7 1.7z"
            fill="#F5F5F5"
          />
          <path
            opacity=".27"
            d="M857.8 259.2c-.5-.2-1-.2-1.5 0 .6.9.7 2.3.2 3.6l-53.2 148.4c-.5 1.3-1.4 2.3-2.5 2.6.3.5.7.8 1.2 1 1.5.5 3.3-.6 4-2.6l53.2-148.4c.7-2 .1-4.1-1.4-4.6z"
            fill="#D8D8D8"
          />
        </g>
        <g opacity=".3">
          <path
            d="M192 380.4v-5.8h-28.5v5.8c0 7.3-5.9 13.3-13.3 13.3-12.9 0-23.3 10.4-23.3 23.3v7.6h101.7V417c0-12.9-10.4-23.3-23.3-23.3-7.3 0-13.3-6-13.3-13.3z"
            fill="#F4F3F4"
          />
          <path
            d="M313.6 374.6H41.9c-2.1 0-3.9-1.7-3.9-3.9V207.9c0-2.1 1.7-3.9 3.9-3.9h271.7c2.1 0 3.9 1.7 3.9 3.9v162.9c0 2.1-1.8 3.8-3.9 3.8z"
            fill="#808181"
          />
          <path
            d="M177.8 363.6a5.5 5.5 0 100-11 5.5 5.5 0 100 11zM306.9 341.5H48.6c-2.1 0-3.9-1.7-3.9-3.9V213.5c0-2.1 1.7-3.9 3.9-3.9h258.3c2.1 0 3.9 1.7 3.9 3.9v124.1c-.1 2.2-1.8 3.9-3.9 3.9z"
            fill="#F4F3F4"
          />
          <path
            d="M236.3 374.6c-.6 55.7 60.1-12.8 60.7 36.8"
            stroke="#4E4D4D"
            strokeWidth="1.654"
            strokeMiterlimit="10"
          />
          <path
            d="M307.9 425.1h-26.4c-1.3 0-2.3-1.1-2.3-2.3v-5.2c0-3.7 3-6.7 6.7-6.7h17.7c3.7 0 6.7 3 6.7 6.7v5.2c-.1 1.3-1.1 2.3-2.4 2.3z"
            fill="#1B1B1B"
          />
        </g>
        <g opacity=".3">
          <path
            d="M364.1 362.8c-2.4 0-4.3-1.9-4.3-4.3v-8.3h-18.4v8.3c0 2.4-1.9 4.3-4.3 4.3-2.2 0-4 1.8-4 4v48.9c0 4.2 3.4 7.5 7.5 7.5h20.1c4.2 0 7.5-3.4 7.5-7.5v-48.9c0-2.1-1.9-4-4.1-4z"
            fill="url(#paint11_linear)"
          />
          <path
            opacity=".1"
            d="M364.1 362.8c-2.4 0-4.3-1.9-4.3-4.3v-8.3h-6.9v8.3c0 2.4 1.9 4.3 4.3 4.3 2.2 0 4 1.8 4 4v48.9c0 4.2-3.4 7.5-7.5 7.5h6.9c4.2 0 7.5-3.4 7.5-7.5v-48.9c.1-2.1-1.8-4-4-4z"
            fill="#D8D8D8"
          />
          <path
            d="M333.1 403.7h16.3c3.3 0 6-2.7 6-6v-12.6c0-3.3-2.7-6-6-6H333v24.6h.1z"
            fill="#3D3D3B"
          />
          <path
            d="M333.4 402.5h18.5c.9 0 1.7-.7 1.7-1.5v-19.2c0-.8-.8-1.5-1.7-1.5H333"
            stroke="#fff"
            strokeWidth=".137"
            strokeMiterlimit="10"
          />
          <path
            d="M359.4 352.1h-17.6c-1.4 0-2.5-1.1-2.5-2.5v-3.1c0-1.4 1.1-2.5 2.5-2.5h17.6c1.4 0 2.5 1.1 2.5 2.5v3.1c0 1.4-1.1 2.5-2.5 2.5z"
            fill="#3A65AE"
          />
          <path
            d="M341.7 345.2v5.8M344 345.2v5.8M346.2 345.2v5.8M348.3 345.2v5.8M350.6 345.2v5.8M352.8 345.2v5.8M354.7 345.2v5.8M357 345.2v5.8M359 345.2v5.8M341.7 345.2v5.8M344 345.2v5.8"
            stroke="#1E1E1C"
            strokeWidth=".457"
            strokeMiterlimit="10"
          />
          <path
            opacity=".1"
            d="M360.6 344h-7.8c1.8 0 3.3 1.5 3.3 3.4v1.4c0 1.9-1.5 3.4-3.3 3.4h7.8c.7 0 1.3-.6 1.3-1.3v-5.5c0-.8-.6-1.4-1.3-1.4z"
            fill="#D8D8D8"
          />
        </g>
        <g opacity=".3">
          <path
            d="M635.2 319.4H611V394c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zM636 319.4h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7H636c2 0 3.7 1.7 3.7 3.7v.7c0 2.1-1.7 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M623.2 409.7c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4v49.8c0 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint12_linear)"
          />
          <path
            d="M623.3 349.9c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint13_linear)"
          />
          <path
            opacity=".27"
            d="M631 319.4v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4H631z"
            fill="#D8D8D8"
          />
          <path
            opacity=".1"
            d="M616.5 387c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c0 1.4-1.2 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
          <path
            d="M734.5 319.4h-24.2V394c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zM735.2 319.4h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7h25.4c2 0 3.7 1.7 3.7 3.7v.7c0 2.1-1.6 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M722.5 409.7c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4v49.8c-.1 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint14_linear)"
          />
          <path
            d="M722.5 349.9c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint15_linear)"
          />
          <path
            opacity=".27"
            d="M730.2 319.4v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4h-4.8z"
            fill="#D8D8D8"
          />
          <path
            opacity=".28"
            d="M715.7 387c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c0 1.4-1.1 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
          <path
            d="M686.2 319.4H662V394c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zM687 319.4h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7H687c2 0 3.7 1.7 3.7 3.7v.7c-.1 2.1-1.7 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M674.2 409.7c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4v49.8c0 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint16_linear)"
          />
          <path
            d="M674.3 349.9c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint17_linear)"
          />
          <path
            opacity=".27"
            d="M682 319.4v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4H682z"
            fill="#D8D8D8"
          />
          <path
            opacity=".1"
            d="M667.4 387c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c.1 1.4-1.1 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
          <path d="M760.3 333h-1.5v84.9h1.5V333z" fill="url(#paint18_linear)" />
          <path
            d="M590.3 333.3h-1.5v84.9h1.5v-84.9z"
            fill="url(#paint19_linear)"
          />
          <path
            d="M780.4 333.3H566.3c-.7 0-1.3-.6-1.3-1.3v-5.4c0-.7.6-1.3 1.3-1.3h214.1c.7 0 1.3.6 1.3 1.3v5.4c.1.7-.5 1.3-1.3 1.3z"
            fill="url(#paint20_linear)"
          />
          <path
            d="M565 361.8v-35.2c0-.7.6-1.3 1.3-1.3h7.1c.7 0 1.3.6 1.3 1.3v35.2c0 .7-.6 1.3-1.3 1.3h-7.1c-.7.1-1.3-.5-1.3-1.3z"
            fill="url(#paint21_linear)"
          />
          <path
            d="M772 361.8v-35.2c0-.7.6-1.3 1.3-1.3h7.1c.7 0 1.3.6 1.3 1.3v35.2c0 .7-.6 1.3-1.3 1.3h-7.1c-.7.1-1.3-.5-1.3-1.3z"
            fill="url(#paint22_linear)"
          />
          <path
            d="M780.4 416.4H566.3c-.7 0-1.3.6-1.3 1.3v5.4c0 .7.6 1.3 1.3 1.3h214.1c.7 0 1.3-.6 1.3-1.3v-5.4c.1-.8-.5-1.3-1.3-1.3z"
            fill="url(#paint23_linear)"
          />
          <path
            d="M565 387.8V423c0 .7.6 1.3 1.3 1.3h7.1c.7 0 1.3-.6 1.3-1.3v-35.2c0-.7-.6-1.3-1.3-1.3h-7.1c-.7-.1-1.3.5-1.3 1.3z"
            fill="url(#paint24_linear)"
          />
          <path
            d="M772 387.8V423c0 .7.6 1.3 1.3 1.3h7.1c.7 0 1.3-.6 1.3-1.3v-35.2c0-.7-.6-1.3-1.3-1.3h-7.1c-.7-.1-1.3.5-1.3 1.3z"
            fill="url(#paint25_linear)"
          />
          <path
            opacity=".28"
            d="M569.9 422.4a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zM569.9 333.3a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zM776.9 422.4a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zM776.9 333.3a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599z"
            fill="#D8D8D8"
          />
          <path
            d="M621.9 355.7c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9 0-1.1-.5-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M622.6 357.6c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.4.1 2.7-1.3 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M618.5 358.1c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M627.1 362.9c.2.2.3.5.3.8 0 .6-.5 1.2-1.1 1.2-.2 0-.4-.1-.6-.2.2.2.5.4.8.4.6 0 1.1-.5 1.1-1.2 0-.5-.2-.8-.5-1z"
            fill="#EBE7D2"
          />
          <path
            d="M627.4 363.6c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.6-.2-.6 0-1.1.5-1.1 1.2 0 .3.1.6.3.8.2.1.4.2.6.2.6 0 1.1-.5 1.1-1.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M625.8 363.8c0-.4.3-.8.7-.8"
            stroke="#fff"
            strokeWidth=".221"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M626.6 385.6c.3.4.6 1 .6 1.6 0 1.3-1 2.4-2.2 2.4-.4 0-.8-.1-1.2-.4.4.5 1 .8 1.6.8 1.2 0 2.2-1.1 2.2-2.4 0-.9-.4-1.6-1-2z"
            fill="#EBE7D2"
          />
          <path
            d="M627.2 387.1c0-.6-.2-1.2-.6-1.6-.3-.2-.8-.4-1.2-.4-1.2 0-2.2 1.1-2.2 2.4 0 .6.2 1.2.6 1.6.3.2.8.4 1.2.4 1.2 0 2.2-1 2.2-2.4z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M623.9 387.6c0-.9.7-1.6 1.5-1.6"
            stroke="#fff"
            strokeWidth=".451"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M624.1 376c.3.4.5.9.5 1.4 0 1.2-.9 2.2-2 2.2-.4 0-.8-.1-1.1-.3.4.4.9.7 1.5.7 1.1 0 2-1 2-2.2 0-.7-.3-1.4-.9-1.8z"
            fill="#EBE7D2"
          />
          <path
            d="M624.6 377.5c0-.6-.2-1.1-.5-1.4-.3-.2-.7-.3-1.1-.3-1.1 0-2 1-2 2.2 0 .6.2 1.1.5 1.4.3.2.7.3 1.1.3 1.1-.1 2-1.1 2-2.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M621.7 377.8c0-.8.6-1.4 1.4-1.4"
            stroke="#fff"
            strokeWidth=".411"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M618.4 337.9c.2.3.3.6.3 1 0 .8-.6 1.5-1.4 1.5-.3 0-.5-.1-.7-.2.3.3.6.5 1 .5.8 0 1.4-.7 1.4-1.5 0-.6-.2-1-.6-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M618.7 338.9c0-.4-.1-.7-.3-1-.2-.1-.5-.2-.7-.2-.8 0-1.4.7-1.4 1.5 0 .4.1.7.3 1 .2.1.5.2.7.2.8-.1 1.4-.7 1.4-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M616.7 339.1c0-.5.4-1 .9-1"
            stroke="#fff"
            strokeWidth=".28"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M625.4 334.9c.2.3.4.6.4 1 0 .8-.7 1.5-1.6 1.5-.3 0-.6-.1-.8-.2.3.3.7.5 1.2.5.9 0 1.6-.7 1.6-1.5-.1-.5-.3-1-.8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M625.8 335.9c0-.4-.1-.7-.4-1-.2-.1-.5-.2-.8-.2-.9 0-1.6.7-1.6 1.5 0 .4.1.7.4 1 .2.1.5.2.8.2.9 0 1.6-.7 1.6-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M623.5 336.2c0-.5.5-1 1.1-1"
            stroke="#fff"
            strokeWidth=".298"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M619 315.4c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M619.7 317.3c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M615.7 317.9c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M625.9 304.1c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.3-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M626.3 305.1c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.8 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M624.1 305.4c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M721.9 356.5c-.4.5-.7 1.2-.7 2 0 1.6 1.2 2.9 2.7 2.9.5 0 1.1-.2 1.5-.5-.5.6-1.2 1-2 1-1.5 0-2.7-1.3-2.7-2.9-.1-1 .4-1.9 1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M721.2 358.5c0-.8.3-1.4.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.7 1.3 2.7 2.9 0 .8-.3 1.4-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.7-1.3-2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M725.2 359c0-1.1-.8-1.9-1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M716.7 363.7c-.2.2-.3.5-.3.8 0 .6.5 1.2 1.1 1.2.2 0 .4-.1.6-.2-.2.2-.5.4-.8.4-.6 0-1.1-.5-1.1-1.2 0-.4.2-.8.5-1z"
            fill="#EBE7D2"
          />
          <path
            d="M716.4 364.5c0-.3.1-.6.3-.8.2-.1.4-.2.6-.2.6 0 1.1.5 1.1 1.2 0 .3-.1.6-.3.8-.2.1-.4.2-.6.2-.6 0-1.1-.6-1.1-1.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M718 364.7c0-.4-.3-.8-.7-.8"
            stroke="#fff"
            strokeWidth=".221"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M717.1 386.4c-.3.4-.6 1-.6 1.6 0 1.3 1 2.4 2.2 2.4.4 0 .8-.1 1.2-.4-.4.5-1 .8-1.6.8-1.2 0-2.2-1.1-2.2-2.4 0-.8.4-1.5 1-2z"
            fill="#EBE7D2"
          />
          <path
            d="M716.6 388c0-.6.2-1.2.6-1.6.3-.2.8-.4 1.2-.4 1.2 0 2.2 1.1 2.2 2.4 0 .6-.2 1.2-.6 1.6-.3.2-.8.4-1.2.4-1.2 0-2.2-1.1-2.2-2.4z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M719.8 388.4c0-.9-.7-1.6-1.5-1.6"
            stroke="#fff"
            strokeWidth=".451"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M719.7 376.9c-.3.4-.5.9-.5 1.4 0 1.2.9 2.2 2 2.2.4 0 .8-.1 1.1-.3-.4.4-.9.7-1.5.7-1.1 0-2-1-2-2.2-.1-.8.3-1.4.9-1.8z"
            fill="#EBE7D2"
          />
          <path
            d="M719.2 378.3c0-.6.2-1.1.5-1.4.3-.2.7-.3 1.1-.3 1.1 0 2 1 2 2.2 0 .6-.2 1.1-.5 1.4-.3.2-.7.3-1.1.3-1.1 0-2-1-2-2.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M722.1 378.7c0-.8-.6-1.4-1.4-1.4"
            stroke="#fff"
            strokeWidth=".411"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M725.4 338.8c-.2.3-.3.6-.3 1 0 .8.6 1.5 1.4 1.5.3 0 .5-.1.7-.2-.3.3-.6.5-1 .5-.8 0-1.4-.7-1.4-1.5-.1-.6.2-1.1.6-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M725 339.7c0-.4.1-.7.3-1 .2-.1.5-.2.7-.2.8 0 1.4.7 1.4 1.5 0 .4-.1.7-.3 1-.2.1-.5.2-.7.2-.8 0-1.4-.7-1.4-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M727 340c0-.5-.4-1-.9-1"
            stroke="#fff"
            strokeWidth=".28"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M718.3 335.8c-.2.3-.4.6-.4 1 0 .8.7 1.5 1.6 1.5.3 0 .6-.1.8-.2-.3.3-.7.5-1.2.5-.9 0-1.6-.7-1.6-1.5.1-.6.4-1 .8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M717.9 336.8c0-.4.1-.7.4-1 .2-.1.5-.2.8-.2.9 0 1.6.7 1.6 1.5 0 .4-.1.7-.4 1-.2.1-.5.2-.8.2-.9 0-1.6-.7-1.6-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M720.2 337.1c0-.5-.5-1-1.1-1"
            stroke="#fff"
            strokeWidth=".298"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M724.8 316.2c-.4.5-.7 1.2-.7 2 0 1.6 1.2 2.9 2.7 2.9.5 0 1.1-.2 1.5-.5-.5.6-1.2 1-2 1-1.5 0-2.7-1.3-2.7-2.9-.1-1 .4-1.9 1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M724.1 318.2c0-.8.3-1.4.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.7 1.3 2.7 2.9 0 .8-.3 1.4-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.7-1.3-2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M728.1 318.7c0-1.1-.8-1.9-1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M717.8 304.9c-.2.3-.4.6-.4 1.1 0 .9.7 1.6 1.5 1.6.3 0 .6-.1.8-.3-.3.3-.7.5-1.1.5-.8 0-1.5-.7-1.5-1.6 0-.5.3-1 .7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M717.5 306c0-.4.1-.8.4-1.1.2-.2.5-.3.8-.3.8 0 1.5.7 1.5 1.6 0 .4-.1.8-.4 1.1-.2.2-.5.3-.8.3-.9 0-1.5-.7-1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M719.6 306.3c0-.6-.5-1-1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M671.2 360.6c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.5-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M671.9 362.5c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M667.9 363.1c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M675.6 371.7c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.2-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M676 372.7c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.9 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M673.8 373c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M677.2 356.8c.2.2.3.5.3.8 0 .7-.5 1.3-1.2 1.3-.2 0-.5-.1-.6-.2.2.3.5.4.9.4.7 0 1.2-.6 1.2-1.3-.1-.4-.3-.8-.6-1z"
            fill="#EBE7D2"
          />
          <path
            d="M677.5 357.7c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.6-.2-.7 0-1.2.6-1.2 1.3 0 .3.1.6.3.8.2.1.4.2.6.2.6-.1 1.2-.6 1.2-1.3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M675.7 357.9c0-.5.4-.8.8-.8"
            stroke="#fff"
            strokeWidth=".244"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M677.5 382.1c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M678.2 384c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.3 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M674.2 384.5c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M674.2 336.6c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.3-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M674.6 337.6c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.8 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M672.4 337.9c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M669.7 314.1c.2.3.4.6.4 1 0 .8-.7 1.5-1.5 1.5-.3 0-.6-.1-.8-.2.3.3.7.5 1.2.5.9 0 1.5-.7 1.5-1.5-.1-.6-.4-1.1-.8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M670.1 315c0-.4-.1-.7-.4-1-.2-.1-.5-.2-.8-.2-.9 0-1.5.7-1.5 1.5 0 .4.1.7.4 1 .2.1.5.2.8.2.8 0 1.5-.6 1.5-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M667.8 315.3c0-.5.5-1 1-1"
            stroke="#fff"
            strokeWidth=".297"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M682.4 301.6c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M683.1 303.5c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M679.1 304.1c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g opacity=".3">
          <path
            d="M531.5 312h-126c-4.8 0-8.6 1.9-8.6 4.3v24.1c0 2.4 3.9 4.3 8.6 4.3h126"
            stroke="url(#paint26_linear)"
            strokeWidth="6.51"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M526.5 341.8H405.1c-4.1 0-7.4-3.3-7.4-7.4v-12c0-4.1 3.3-7.4 7.4-7.4h121.4v26.8z"
            fill="#fff"
          />
          <path
            d="M400.3 320h122.1M400.3 324.4h122.1M400.3 328.7h122.1M400.3 333.1h122.1M400.3 337.5h122.1"
            stroke="#CDCCCC"
            strokeWidth="2.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M384 349.8h126c4.8 0 8.6 1.9 8.6 4.3v24.1c0 2.4-3.9 4.3-8.6 4.3H384"
            stroke="#066938"
            strokeWidth="6.51"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M389 379.6h121.4c4.1 0 7.4-3.3 7.4-7.4v-12c0-4.1-3.3-7.4-7.4-7.4H389v26.8z"
            fill="#fff"
          />
          <path
            d="M515.2 357.8H393.1M515.2 362.2H393.1M515.2 366.5H393.1M515.2 370.9H393.1M515.2 375.2H393.1"
            stroke="#CDCCCC"
            strokeWidth="2.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M543.8 388.3h-126c-4.8 0-8.6 1.9-8.6 4.3v24.1c0 2.4 3.9 4.3 8.6 4.3h126"
            stroke="url(#paint27_linear)"
            strokeWidth="6.51"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M538.7 418.1H417.3c-4.1 0-7.4-3.3-7.4-7.4v-12c0-4.1 3.3-7.4 7.4-7.4h121.4v26.8z"
            fill="#fff"
          />
          <path
            d="M412.6 396.3h122M412.6 400.7h122M412.6 405h122M412.6 409.4h122M412.6 413.8h122"
            stroke="#CDCCCC"
            strokeWidth="2.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M1064.77 230.441v-.364c0-5.64-4.73-10.37-10.55-10.37s-10.55 4.549-10.55 10.37v.364h21.1z"
          fill="#372C28"
        />
        <path
          d="M1096.06 234.626v-.728c0-9.643-8-17.466-17.83-17.466-9.82 0-17.83 7.823-17.83 17.466v.728h35.66z"
          fill="#372C28"
        />
        <path
          d="M1113.71 232.443v-.546c0-8.187-6.37-14.737-14.37-14.737-8.01 0-14.37 6.55-14.37 14.737v.546h28.74z"
          fill="#372C28"
        />
        <path
          d="M1113.17 424.748h-89.15c-8.19 0-15.1-6.731-15.1-15.1V254.639h119.16V409.83c0 8.187-6.73 14.918-14.91 14.918z"
          fill="#E28F5C"
        />
        <path
          opacity=".27"
          d="M1034.39 379.628h-5.46c-3.09 0-5.64-2.547-5.64-5.64v-68.589c0-3.093 2.55-5.64 5.64-5.64h5.46c3.09 0 5.64 2.547 5.64 5.64v68.589c0 3.093-2.55 5.64-5.64 5.64z"
          fill="#D8D8D8"
        />
        <path
          d="M1057.13 280.11h-42.21v.727h42.21v-.727zM1057.13 308.856h-42.21v.727h42.21v-.727zM1057.13 337.601h-42.21v.728h42.21v-.728zM1057.13 366.529h-42.21v.728h42.21v-.728zM1057.13 395.275h-42.21v.728h42.21v-.728zM1030.75 290.298h-15.1v.728h15.1v-.728zM1030.75 284.112h-15.1v.728h15.1v-.728zM1030.75 296.484h-15.1v.728h15.1v-.728zM1030.75 302.67h-15.1v.727h15.1v-.727zM1030.75 319.226h-15.1v.728h15.1v-.728zM1030.75 313.04h-15.1v.728h15.1v-.728zM1030.75 325.412h-15.1v.727h15.1v-.727zM1030.75 331.598h-15.1v.727h15.1v-.727zM1030.75 349.063h-15.1v.728h15.1v-.728zM1030.75 342.877h-15.1v.728h15.1v-.728zM1030.75 355.249h-15.1v.728h15.1v-.728zM1030.75 361.435h-15.1v.728h15.1v-.728zM1030.75 377.809h-15.1v.728h15.1v-.728zM1030.75 371.623h-15.1v.728h15.1v-.728zM1030.75 383.995h-15.1v.727h15.1v-.727zM1030.75 390.181h-15.1v.727h15.1v-.727z"
          fill="#1E1E1C"
        />
        <path
          d="M1128.08 236.263c0-9.642-7.64-17.466-17.1-17.466-4.55 0-8.55 1.82-11.64 4.731-2-1.274-4.55-2.001-7.1-2.001-5.09 0-9.46 2.729-12 6.731a9.418 9.418 0 00-5.83-2.001c-3.09 0-6 1.455-7.64 3.82a8.883 8.883 0 00-7.28-3.82c-2 0-4 .728-5.45 2.001-2.37-4.002-6.55-6.731-11.28-6.731-2.37 0-4.73.727-6.74 2.001-2.91-2.911-6.73-4.731-10.91-4.731-8.92 0-16.01 7.824-16.01 17.466H1128.08z"
          fill="#654B47"
        />
        <path
          d="M1128.08 254.639h-119.16v17.284h119.16v-17.284z"
          fill="#C6603F"
        />
        <path
          d="M1128.08 241.539c0-9.642-7.64-17.466-17.1-17.466-4.55 0-8.55 1.82-11.64 4.731-2-1.274-4.55-2.001-7.1-2.001-5.09 0-9.46 2.729-12 6.731a9.418 9.418 0 00-5.83-2.001c-3.09 0-6 1.455-7.64 3.82a8.883 8.883 0 00-7.28-3.82c-2 0-4 .728-5.45 2.001-2.37-4.002-6.55-6.731-11.28-6.731-2.37 0-4.73.727-6.74 2.001-2.91-2.911-6.73-4.731-10.91-4.731-8.92 0-16.01 7.824-16.01 17.466H1128.08z"
          fill="#1E1E1C"
        />
        <path
          d="M1129.54 254.639h-122.08c-5.28 0-9.46-4.185-9.46-9.461v-6.004a2.874 2.874 0 012.91-2.911h135.18c1.64 0 2.91 1.274 2.91 2.911v6.004c0 5.094-4.18 9.461-9.46 9.461z"
          fill="#E28F5C"
        />
        <path
          opacity=".27"
          d="M1107.89 254.639v162.832c0 4.002-3.64 7.277-8.19 7.277h20.2c4.55 0 8.18-3.275 8.18-7.277V254.639h-20.19z"
          fill="#D8D8D8"
        />
        <path
          d="M1121.17 424.748h-105.7a6.522 6.522 0 01-6.55-6.549v-3.639h119.16v3.275a6.907 6.907 0 01-6.91 6.913z"
          fill="#C6603F"
        />
        <path
          className={GardenPageStyles.sparkles}
          d="M996 286.5c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM1000 17.5c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM1173 381.5c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM21 110.5c-14.4 0-9.8-17.5-9.8-17.5H9.8s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM382 327.5c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM21 389.5c-14.4 0-9.8-17.5-9.8-17.5H9.8s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5z"
          fill="#EFE9E2"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="1031.44"
            y1="213.211"
            x2="1023.08"
            y2="190.036"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="1033.28"
            y1="200.137"
            x2="1044.45"
            y2="212.984"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="1108.23"
            y1="131.686"
            x2="1123.39"
            y2="151.111"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="1095.16"
            y1="182.225"
            x2="1105.88"
            y2="200.832"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="1067.44"
            y1="123.992"
            x2="1084.77"
            y2="131.968"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="1077.83"
            y1="174.389"
            x2="1096.24"
            y2="190.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="1035.56"
            y1="185.003"
            x2="1049.25"
            y2="164.523"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2EAC68" />
            <stop offset="1" stopColor="#3AAA35" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="597"
            y1="491.017"
            x2="597"
            y2="441.062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B0785D" />
            <stop offset=".485" stopColor="#AF775C" />
            <stop offset=".659" stopColor="#A97258" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="847.757"
            y1="392.521"
            x2="799.102"
            y2="392.521"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="860.968"
            y1="413.344"
            x2="881.456"
            y2="333.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BEAF" />
            <stop offset=".76" stopColor="#B4DBD1" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="940.373"
            y1="393.004"
            x2="989.038"
            y2="393.004"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint11_linear"
            x1="345.241"
            y1="399.037"
            x2="364.624"
            y2="368.458"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#542A14" />
            <stop offset="1" stopColor="#C35E19" />
          </linearGradient>
          <linearGradient
            id="paint12_linear"
            x1="635.378"
            y1="378.724"
            x2="611.001"
            y2="378.724"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint13_linear"
            x1="620.141"
            y1="353.237"
            x2="628.737"
            y2="338.347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F39FA6" />
            <stop offset=".569" stopColor="#DF6171" />
          </linearGradient>
          <linearGradient
            id="paint14_linear"
            x1="706.309"
            y1="402.283"
            x2="748.134"
            y2="329.841"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".239" stopColor="#F3A0A6" />
            <stop offset=".676" stopColor="#F0EBE4" />
          </linearGradient>
          <linearGradient
            id="paint15_linear"
            x1="719.394"
            y1="353.237"
            x2="727.991"
            y2="338.347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".239" stopColor="#F3A0A6" />
            <stop offset=".676" stopColor="#F0EBE4" />
          </linearGradient>
          <linearGradient
            id="paint16_linear"
            x1="658.026"
            y1="402.283"
            x2="699.85"
            y2="329.841"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BEAF" />
            <stop offset=".76" stopColor="#B4DBD1" />
          </linearGradient>
          <linearGradient
            id="paint17_linear"
            x1="671.11"
            y1="353.237"
            x2="679.707"
            y2="338.347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BEAF" />
            <stop offset=".76" stopColor="#B4DBD1" />
          </linearGradient>
          <linearGradient
            id="paint18_linear"
            x1="758.833"
            y1="375.405"
            x2="760.377"
            y2="375.405"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint19_linear"
            x1="588.805"
            y1="375.731"
            x2="590.349"
            y2="375.731"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint20_linear"
            x1="565.001"
            y1="329.266"
            x2="781.75"
            y2="329.266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint21_linear"
            x1="565.001"
            y1="344.224"
            x2="574.767"
            y2="344.224"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint22_linear"
            x1="771.984"
            y1="344.224"
            x2="781.75"
            y2="344.224"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint23_linear"
            x1="565.001"
            y1="420.365"
            x2="781.75"
            y2="420.365"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint24_linear"
            x1="565.001"
            y1="405.407"
            x2="574.767"
            y2="405.407"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint25_linear"
            x1="771.984"
            y1="405.407"
            x2="781.75"
            y2="405.407"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint26_linear"
            x1="393.655"
            y1="328.368"
            x2="572.445"
            y2="328.368"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint27_linear"
            x1="405.895"
            y1="404.668"
            x2="547.045"
            y2="404.668"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B0785D" />
            <stop offset=".485" stopColor="#AF775C" />
            <stop offset=".659" stopColor="#A97258" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
